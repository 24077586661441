<template>
    <!-- <v-alert class="my-2" outlined color="success" text>
        <div class="text-h6 success--text">Example</div>
        <div class="text-body-1">
            <slot v-bind:text="text">{{ text }}</slot>
        </div>
    </v-alert> -->
    <v-alert border="left" colored-border color="success">
        <div class="text-h6 success--text">Example</div>
        <div class="text-body-1">
            <slot v-bind:text="text">{{ text }}</slot>
        </div>
    </v-alert>
</template>

<script>
export default {
    name: 'Setup-Example',
    props: {
        text: {
            type: String,
            default: null
        }
    }
}
</script>